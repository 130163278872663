.fb-msform {
    display: inline-block;
    /*width: 174vmin;
    height: 90vmin;*/
    vertical-align: -0.125em;
    fill: currentColor;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 16 / 8.2;
    border: 0px solid red;
    /*    min-height: 200px;
    min-width: 300px;*/
}
/*html, body {
    height: 100%;
}*/


/*    .fb-msform iframe {
        border: 0px solid red;
    }*/