.login-buttons button {
    border-radius: 50%; /* ����¹���������ǧ��� */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); /* ������ */
    transition: background-color 0.3s ease; /* ����¹�ջ�������ͤ�ԡ */
    width: 250px; /* ��˹��������ҧ�ͧ������ 250px */
    margin-right: 10px; /* ���������ҧ�����ҧ���� */
}
.login-buttons .login-button-forgot-password {
    /* ... ��Ѻ��������� "������ʼ�ҹ" ... */
    /*text-align: right;*/ /* �Ѵ��ͤ����Դ��� */
    /*float:right;*/
}

    .login-buttons button:hover {
        background-color: #007bff; /* ����¹�ջ��������������� */
    }
.login-container {
    display: flex; /* Enables centering elements */
    flex-direction: column; /* Stacks elements vertically */
    align-items: center; /* Centers elements horizontally */
    justify-content: center; /* Centers elements vertically */
    height: 100vh;
    /*background-color: #f0f0f0;*/
}

.login-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 8px; /* ��������������Ѻ Card */

}
.login-card {
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    text-align: center;
    width: 300px;
}

.login-card-content {
   
}




.button-container {
    cursor: pointer; /* Indicate clickable area */
    border: 2px solid #ccc; /* Add a border */
    padding: 5px; /* Add some padding around the SVG */
}

    .button-container svg {
        transition: background-color 0.2s ease; /* Add a smooth transition for hover effect */
    }

        .button-container svg:hover {
            background-color: #0056b3; /* Change background color on hover */
        }

